#contact {
  display: flex;
  justify-content: center;
  background: rgb(0, 0, 0);
  color: rgb(108, 108, 108);
}
#contact > * {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10vw;
  max-width: 1000px;
  width: 100%;
  padding: 20px 0;
}

.social-network {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 0 10px;
}
.social-network div {
  width: max-content;
  display: flex;
  gap: 20px;
}

.social-network div > * {
  color: inherit;
}
.social-network .social-icon {
  font-size: 30px;
}

.social-network a:hover {
  color: brown;
}

#contact form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 800px;
}

#contact input,
textarea {
  margin-bottom: 10px;
  border: 2px solid black;
  margin-top: 5px;
  padding-left: 10px;
  outline: none;
  background: lightgray;
}

#contact input:focus,
#contact textarea:focus {
  border: 2px solid green;
}

#contact textarea {
  height: 100px;
}

#contact button {
  width: 100%;
  margin: auto;
  background: green;
  color: white;
  border-radius: 5px;
  border: 0;
}

#contact button:active {
  background: gray;
  color: black;
}

@media (max-width: 770px) {
  #contact > div {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10vw;
  }
 
  #contact form{
    max-width: unset;
  }
  .social-network>h4 {
    display: none;
  }
}
@media (max-width: 550px) {
  #contact > div {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }
  #contact > div > * {
    width: 100%;
    flex-grow: 1;
  }

  
  .social-network {
    border-top: 1px solid rgb(83, 83, 83);
  }
}
