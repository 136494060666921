#project {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#project > div {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  max-width: 1000px;
}

.overflow {
  width: 100%;
  max-height: 480px;
  position: relative;
  overflow-x: auto;
}

.projects-ctn {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  padding: 20px;
  min-width: max-content;
}

.projects-ctn .link {
  position: relative;
  max-height: 490px;
  min-width: 200px;
  max-width: 280px;
  width: 100%;
  height: 100%;
}

.project {
  max-height: 490px;
  width: 100%;
  min-width: 85%;
  border: 1px solid black;
  position: relative;
}

.project:hover {
  box-shadow: 0 0 10px black;
}

.project .image {
  padding: 5px 15px;
  background: white;
  position: relative;
  height: 300px;
}

.project img {
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.project > h4 {
  text-align: center;
  background: black;
  color: white;
  margin: 0;
  padding: 10px;
  font-size: 23px;
}

.section-title {
  text-align: center;
  font-size:40px
}

@media (max-width: 800px) {
  #project .projects-ctn {
    flex-direction: row;
    align-items: center;
  }
  

  .projects-ctn .link {
    position: relative;
    max-height: 490px;
    min-width: 250px;
    max-width: 260px;
    box-shadow: 0 0 5px black;
  }

  .projects-ctn {
    justify-content: center;
    gap: 50px;
  }
}
