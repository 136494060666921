.tech-list {
  display: flex;
  flex-wrap: wrap;

  .tech {
    border: 1px solid black;
    border-radius: 10px;
    font-size: 14px;
    padding: 2px 10px;
    color: yellow;
    background: black;
  }
}

#experience{
  display: flex;
  justify-content: center;
  background-color: rgb(0, 82, 97);
  color: white;

  .experience-content{
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  // ul{
  //   list-style: none;
  // }

  .role{
    font-weight: bold;
    margin-bottom: 0;
    color: yellow;
    margin-top: 10px;
  }
}

#experience > div {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 1000px;
}
