.next-drawing {
  display: flex;
  justify-content: center;

  div {
    max-width: max-content;
    background-color: black;
    padding: 5px;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h6 {
      font-weight: bold;
      padding-bottom: 5px;
      width: max-content;
      font-size: 25px;
      text-align: center;
      width: 100%;
      text-wrap: wrap;
      margin-bottom: 10px;
    }
    
    .date_time {
      h6 {
        font-size: 25px;
        border-bottom: 5px solid rgba(230, 230, 1, 0.9);
        color: rgba(230, 230, 1, 0.9);
      }
  
      p {
        font-size: 20px;
        text-align: center;
        margin-bottom: 0;
      }
  
      .time {
        color: white;
        font-size: 30px;
      }
    }
  
    .countdown_ctn {
      h6{
        margin-bottom: 5px;
        padding-bottom: 0;
        font-size: 20px;
        margin-top: 10px;
        width: max-content;
      }
      .countdown{
        padding: 0;

        #time_countdown{
          display: flex;
          margin-bottom: 0;
          padding: 0 5px;
          border-radius: 5px;
          width: 100%;
          .time{
            display: flex;
            font-size: 30px;
            min-width: 50px;
          }
        }
      }
    }
    

    .mb-3{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }
}






