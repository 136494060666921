.combo-num,
.range-txt {
  min-width: 200px;
  font-size: 20px;
}

.combo {
  display: flex;
  font-size: 20px;
}

.combo-num {
  max-width: max-content;
}

.range,
#nCombo {
  width: 60px;
  border: 0;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  outline: none;
  height: 30px;
}

.combo-title {
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
