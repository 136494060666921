.calculator {
    background-color: rgb(233, 233, 233);
    padding: 2px;
    border-radius: 10px;
    margin: 50px auto;
    max-width: 800px;


    table {
        border-collapse: collapse;
        box-shadow: 0 0 2px black;
    }

    td {
        padding: 5px
    }

    tr:nth-child(odd) {
        background-color: rgb(255, 253, 253);

    }

    .calculator_header {
        font-size: 50px;
        text-align: center;
        margin: 0;
        background-color: black;
        color: white;
        border-radius: 10px 10px 0 0;
    }

    .salary_div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;

        input {
            outline: none;
            border: none;
            border-bottom: 1px solid rgba(88, 88, 0, 0.514);
            padding-left: 10px;
            border-radius: 5px;
            flex-grow: 1;
            background-color: rgba(255, 255, 0, 0.051);
            font-size: 30px;
            width: 100%;
        }
    }

    .form {
        padding: 0 10px;
        margin-top: 0;
        padding-bottom: 20px;



        label {
            font-weight: 500;
            text-transform: capitalize;
            font-size: 30px;
            margin: 0;
            padding: 0;
            flex-grow: 1;
        }

        #salary_type_div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            div {
                display: flex;
                // gap: 1px;
                align-items: center;
            }

            .salary_type {
                font-size: 14px;
                font-weight: 100;
                margin: 0.4rem;
            }
        }

        #salary_input {
            outline: none;
            border: none;
            border-bottom: 1px solid rgba(88, 88, 0, 0.514);
            padding-left: 10px;
            border-radius: 5px;
            flex-grow: 1;
            background-color: rgba(255, 255, 0, 0.051);
            font-size: 30px;
            width: 100%;
        }

        button {
            border: 0;
            margin: auto;
            padding: 3px 20px;
            border-radius: 5px;
        }
    }

}