.navbar-brand > span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateZ(-45deg);
  font-weight: bold;
  background: rgb(0, 0, 0);
  border-radius: 2px;
  font-size: 25px;
  color: white;
}

.nav-ctn {
  background: rgb(230, 225, 225);
  border-bottom: 1px solid rgb(255, 255, 255);
  position: fixed;
  width: 100%;
  z-index: 2;
}
.navbar {
  color: white;
  max-width: 1000px;
  margin: auto;
}

.navbar-brand {
  transform: rotateZ(45deg);
  text-shadow: 1px 0px 1px white, -1px 0px 1px;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 2px;
  margin: 0;
}
.navbar-nav {
  gap: 10px;
}

.navbar-collapse .nav-link:hover {
  background: black;
  color: white;
}

.navbar-collapse .nav-link {
  color: black;
  font-weight: 600;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 0;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .navbar-nav {
    padding-top: 10px;
  }
  .nav-link {
    padding-left: 5px;
  }
}
