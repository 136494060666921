.result_div {
    border-top: 1px solid lightgray;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    font-size: 20px;

    table {
        border-collapse: collapse;
        border: 1px solid black;

        .tax_percent{
            font-size: 40px;
            background-color: black;
            color: white;
            text-align: center;
        }
        td {
            padding: 5px
        }
        
        tr:nth-child(odd) {
            background-color: rgb(227, 227, 227);
    
        }
    }


    .result.number {
        max-width: 800px;
        margin: auto;

        h3 {
            font-size: 25px;
            font-weight: bold;
            margin-top: 20px;
        }

        div {
            margin: 40px auto;
        }

        .period{
            box-shadow: 0 0 2px black;
            max-width: 800px;
            width: 100%;
            margin-top: 30px;

       

            .details{
                padding: 5px;               

                summary{
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 100%;

                    p {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0;
                        strong{
                            color: green;
                        }
                    }
                }
            }

            .details[open]{
                background-color: rgb(44, 44, 44);
                color: white;
                
                margin-bottom: 10px;
                    
                summary{
                    color: yellow;
                    strong{
                        color: yellowgreen;
                    }
                }

                table{
                    margin: 0;
                    font-size: 17px;
                    border: unset;                    

                    tr {
                        border: 1px solid gray;
                        border-left: 0;
                        border-right: 0;
                        background-color: unset;
                        
                        td:nth-child(even){
                            color: red;
                        }
                    }
                }


            }

            details:nth-child(odd) {
                background-color: rgb(255, 253, 253);
            }
           
        }
    }

    table {
        max-width: 800px;
        width: 100%;
        margin-top: 20px;

        .value {
            text-align: right;
            font-weight: bold;
        }
    }

    .take_home {
        font-size: 40px;
        color: green;
    }
}