#interest h3 {
  padding: 0;
  height: max-content;
  display: flex;
  align-items: flex-start;
}

#interest {
  width: max-content;
  max-width: max-content;
  min-width: max-content;
}
