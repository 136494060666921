#intro {
  background: rgb(0, 5, 8);
  color: rgb(230, 225, 225);
  display: flex;
  justify-content: center;

  .name{
    font-size: 40px;
    font-family: Marker Felt, fantasy;
  }
}

#intro .skills {
  display: flex;
  gap: 10px;
}

#intro .skills > span > span {
  font-size: 12px;
}

#intro .skills > span {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#intro > div {
  display: flex;
  gap: 20px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  max-width: 1000px;
}

.briefing {
  max-width: 600px;
  padding-top: 10px;
}

.role {
  width: max-content;
}
.social {
  display: flex;
  gap: 10px;
}

.social > * {
  color: rgb(112, 124, 0);
}

.social .social-icon {
  font-size: 30px;
}
.social a:hover {
  color: brown;
}

.profile-ctn {
  width: 100%;
  max-width: 360px;
  min-width: 300px;
  overflow: hidden;
  position: relative;
  padding: 30px;
}

#intro .summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5px;
}

.profile-ctn img {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 0;
  border: 2px solid rgb(114, 114, 114);
  /* background-color: rgba(92, 2, 85, 0.226); */
}

@media (max-width: 730px) {
  #intro .profile-ctn,
  .briefing {
    margin: auto;
  }

  .role {
    padding-bottom: 5px;
    border-bottom: 0;
  }
  .name,
  .role,
  .location {
    width: 100%;
  }

  #intro > div {
    flex-direction: row;
    padding-bottom: 0;
    gap: 2%;
  }
}

@media (max-width: 675px) {


  #intro .profile-ctn {
    margin-left: 0;
  }
}

@media (max-width: 620px) {
  #intro > div {
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-bottom: 10px;
  }

  #intro .skills {
    display: flex;
    justify-content: center;
  }
  #intro > div > * {
    width: 100%;
    max-width: none;
  }

  .profile-ctn img {
    width: 70%;
  }

  .profile-ctn {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  #intro .name {
    font-size: 35px;
  }

  #intro .role {
    font-size: 25px;
  }
  #intro .location {
    font-size: 20px;
    font-family: Apple Chancery,cursive;
  }

  #intro .social-icon {
    font-size: 25px;
  }

  #intro .summary {
    text-align: center;
  }

  #intro .social {
    justify-content: center;
    margin-bottom: 20px;
  }
}
