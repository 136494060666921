
#about{
    background: rgb(254, 247, 247);
    display: flex;
    justify-content: center;
}
.about-content {
  display: flex;
  gap: 70px;
}

#about > div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.content-div {
  width: 100%;
  flex-grow: 1;
  max-width: 600px;
  font-size: 20px;
}
@media (max-width: 1015px) {
  #about {
    background: rgb(254, 247, 247);
  }
}

@media (max-width: 850px) {
  .about-content {
    flex-direction: column;
    gap: 10px;
  }

  .content-div{
    max-width: 900px;
  }
}
