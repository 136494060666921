#footer {
  text-align: center;
  padding: 0;
  background-color: black;

  p{
    margin: 0;
    color: rgba(128, 128, 128, 0.414);
    border-top: 1px solid rgba(128, 128, 128, 0.345);
    font-size: 14px;
  }
}
