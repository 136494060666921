
#income{
    background-color: rgb(247, 245, 245);
    max-width: unset;
    display: flex;
    justify-content: center;
    font-size: 20px;
    .contentDiv{
        max-width: 1000px;
        padding-top: 60px;        
    }
}