.homepage {
  position: relative;
  background: rgb(230, 225, 225);
  padding-top: 50px;
}
.homepage > * {
  width: 100%;

  margin: auto;
}
.component {
  display: flex;
  justify-content: center;
}

.component > * {
  padding: 30px 0;
  height: max-content;
}
a {
  text-decoration: none;
}
.social-icon {
  font-size: 20px;
}

.component .section-title {
  max-width: 500px;
  padding: 0 10px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 2px solid black;
}

@media (max-width: 1015px) {
  .homepage > * {
    border: 1px solid black;
    background: rgb(255, 255, 255);
  }
}

@media (max-width: 730px) {
  .component > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .component > * > * {
    width: 95%;
  }
  .component .section-title {
    text-align: center;
  }
}
