.past-result-container {
  width: max-content;
  min-width: 350px;
  height: max-content;
  position: relative;
  background: rgba(230, 230, 230, 0.619);
  padding-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px black;
}

.past-result-container > .header {
  display: flex;
  align-items: center;
  background: lightblue;
  gap: 10px;
  width: 100%;
  padding: 0 5px;
  margin: auto;
  border-radius: 5px 5px 0 0;
}

.page-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.page-button > * {
  width: 50px;
  height: 30px;
  border: 0;
  border-radius: 5px;
}

.page-number {
  background: red;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.past-result-container > .header > p {
  font-weight: 500;
  font-size: 26px;
  margin: 0;
}

.past-result-container > .header > select {
  outline: none;
  border: 0;
  background: rgba(211, 211, 211, 0.423);
}

.past-result {
  height: max-content;
  width: 100%;
  overflow-y: auto;
  margin-top: 0;
  border-radius: 5px;
  gap: 50px;

  p {
    margin: 0;
  }
  .text-start{
    *{
      margin: 0;
    }
  }
}




.past-result > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 10px 5px;
}

.date {
  width: max-content;
  text-align: left;
}

.powerball {
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.powerball > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
}

.red-ball {
  color: white;
  background: red;
}

.white-ball {
  background: white;
}
