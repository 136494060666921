.school {
  display: flex;
  gap: 20px;
  max-height: max-content;
}

#education{
  display: flex;
  justify-content: center;
  font-size: 20px;

  .content{
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 1000px;
  }
}



.time-interval,
.details {
  display: flex;
  flex-direction: column;
  position: relative;
}
.bi.bi-arrow-down {
  height: 50px;
}

.details > p {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.details a {
  color: black;
  text-decoration: none;
  width: max-content;
  font-weight: 500;
  font-size: 20px;
}

.details a:hover {
  color: brown;
}
.skill-icons {
  display: flex;
  gap: 10px;
}
.skill-icons > span {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skill-icons > span > span {
  font-size: 12px;
}

.education-content {
  display: flex;
  gap: 70px;
}
.education-content > div {
  width: 100%;
}
.inner-time-interval {
  font-size: 14px;
  width: 100%;
  padding-left: 10px;
}

.certification a {
  color: black;
}
.certification a:hover {
  color: brown;
}

@media (max-width: 1015px) {
  #education {
    background: rgb(230, 225, 225);

    .content{
      width: 100%;
    }
  }
}

@media (max-width: 745px) {
  .education-content {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 504px) {
  .time-interval {
    display: none;
  }
  .inner-time-interval {
    display: flex;
  }
}
