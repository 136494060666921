.frequency-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightblue;
  font-weight: 500;
  font-size: 27px;
  border-radius: 5px 5px 0 0;
}

.frequency-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.frequency-element {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.frequency-element > .white-ball,
.frequency-element > .red-ball {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgb(119, 119, 119);
}

.white,
.red {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 5px;
  /* height: 400px; */
  padding: 5px 10px;
  background: rgb(241, 241, 241);
  width: 100%;
}

.white {
  border-radius: 0 0 0 4px;
}

.red {
  border-radius: 0 0 4px 0;
}

.white > .head,
.red > .head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-bottom: 1px solid black;
  height: 50px;
  margin-bottom: 5px;
}

.white > .head > input {
  border: 0;
  outline: none;
  width: 35px;
  padding-left: 5px;
  background: rgba(255, 255, 255, 0.524);
}

.white {
  width: 100%;
}

.frequency-white-container,
.frequency-red-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.frequency-div-container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 2px;
  width: 100%;
  // margin-bottom: 20px;
  background: rgba(39, 39, 39, 1);
  border-radius: 5px;
}

.frequency-div-container .page-button > * {
  width: 20px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frequency-container {
  max-width: 350px;
  width: 100%;
  height: max-content;
  box-shadow: 0 0 5px black;
  border-radius: 5px;

  p{
    margin: 0;
  }
}
