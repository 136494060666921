#lotto {
  max-width: none;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  
  .lotto {
      position: relative;
      width: 100%;
      max-width: 1000px;
      padding-top: 40px;
      margin: auto;
    }
}

.about-services {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  gap: 10px;
  margin: 10px 0;
  height: 100%;
}

@media (max-width: 970px) {
  .about-services {
    padding: 0 1vw;
  }
}

@media (max-width: 910px) {
  .about-services {
    flex-direction: column;
  }
}
